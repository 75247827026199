<template>
  <v-card class="elevation-16 mx-auto" width="400">
    <v-card-title class="headline" primary-title>
      <span style="font-family:'Cairo_Semi'" class="mb-3"> قيم هذا العنصر</span>
    </v-card-title>
    <v-layout justify-center="" class="mb-2">
      <v-rating v-model="rating" color="yellow darken-3" background-color="grey darken-1" empty-icon="$ratingFull"
        half-increments hover></v-rating>
    </v-layout>
    <v-card-text>
      اعط رأيك لمساعدتنا بتقديم خدمة افضل




      <v-container>
        <v-text-field  placeholder="اكتب رأيك" v-model="disc" outlined=""></v-text-field>
      </v-container>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-between">
      <v-btn color="primary" text @click="rating_reservation">
        تقييم الان
      </v-btn>
      <v-btn text @click="dispose">لا شكرا</v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      'item_id': Number
    },
    data: () => ({
      rating: 0,
      disc: " ",
    
    }),

      mounted() {

    

    },
    methods: {


      rating_reservation() {
      
      

        var post_data = {
          item_id: this.item_id,
          rate_value: this.rating,
          comment:this.disc
        }
       this.$http({
            method: 'post',
            url: "/ratings/user/set",
            data: post_data,
            headers: {
            
            }
          })
          .then(response => {



            if (response.data.success == true) {

              this.$swal('', "    تم  التقييم بنجاح", 'success')
              this.$emit("hiddenDailog", "false");
            }
          })

          .catch(error => {
            error
            
            //booking item error
            this.$swal('', " فشل التقييم", 'error')
          }).finally(d => {
            d,
            this.loading = false;
          });
      },
      dispose() {

       
      }


    },



  }
</script>