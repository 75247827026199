<template>


  <v-container fluid="" pr-2 pl-2>

    <v-layout row wrap  class="pad_item">
      <!-- section 1 to images of item -->



      <v-flex xs12 class="hidden-lg-and-up">

        <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="img in iMages" :src="getPath(iMages[model].image_url)" :key="img.id">


          </v-carousel-item>
        </v-carousel>

      </v-flex>
      <v-flex md5 xs12 order-xs="12" class="hidden-md-and-down" offset-md="12">
        <v-flex xs12>
          <v-layout row wrap pl-5 pt-4>

            <v-sheet class="mx-auto" elevation="8" style="width:100%" height="635">
              <v-expand-transition>
                <v-sheet color="grey lighten-4" height="300" tile>
                  <div class="fill-height" align="center" justify="center" style="width:100%">
                    <v-img height="300px" :src="getPath(iMages[model].image_url)" class="card_img"></v-img>
                  </div>
                </v-sheet>
              </v-expand-transition>


              <v-slide-group v-model="model" class="pa-4" show-arrows dir="ltr">
                <v-slide-item v-for="img in iMages" dir="ltr" v-slot:default="{ active, toggle }" :key="img.id">
                  <v-card :color="active ? 'primary' : 'grey lighten-1'" class="ma-4" height="200" width="200"
                    @click="toggle">


                    <v-row class="fill-height" align="center" justify="center">



                      <v-img height="200px" :src="getPath(img.image_url)" class="card_img">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                    </v-row>

                  </v-card>
                </v-slide-item>
              </v-slide-group>





            </v-sheet>


          </v-layout>
        </v-flex>


      </v-flex>







      <!-- section 2 for item details -->
      <v-flex md7 xs12 pr-4>

        <v-container>

          <!-- {{ info.item_name}} -->
          <v-layout row wrap>



            <v-row class="mb-4">
              <span style="padding-right:10px;font-size:22px;font-weight:bold">{{items.owner.owner_barnd_name}} | {{items.item_name}}  </span>
            </v-row>
          </v-layout>





          <v-layout row wrap>

            <v-flex xs12 md4 sm4 pt-3>
              <span style="padding-right:10px;padding-left:10px;padding-top:5px">القسم :{{items.sub_category.name}}
              </span>

            </v-flex>

            <v-flex xs8 md3 sm3>

              <!-- <hr> -->
              <v-rating color="#f29c2b" background-color="gray" @click="booking_dialog=true" readonly="" size="25"
                v-model="items.rate">
              </v-rating>

            </v-flex>
            <v-flex xs4 md1 sm1 pr-3 pt-2>
              <div v-if="this.$cookies.get('user_info') !==null ">
                <div v-if="this.$cookies.get('user_info').role =='user' ">

                  <v-btn @click="rating_dailog=true" text color="primary">
                    تقيم
                  </v-btn>


                </div>
              </div>
            </v-flex>
            <!-- <v-flex xs12 sm1 md1>

            </v-flex> -->

            <v-flex xs12 md4 sm4 v-for="item in items.offer" :key="item.id" pt-3>




              <div class="d-inline " style="background-color: #f29c2b;color: #fff;font-weight: bold;">
                <v-icon right="" style="color:#fff">mdi-star</v-icon>
                <v-icon>
                </v-icon>



                {{item.offer_name}} {{item.discount_percentage}} ٪


              </div>







            </v-flex>





          </v-layout>



          <v-divider class="mb-2 hidden-md-and-down"></v-divider>
          <v-row>
            <v-flex xs12 md7 style="padding :20px">
              <v-row>
                <v-col>
                  <label style="font-weight:bold">الموقع</label><br><br>
                  <label style="font-weight:bold">سعر الحجز</label><br><br>
                  <label style="font-weight:bold">امكانية الغاء الحجز</label><br><br>

                  <!-- <label style="font-weight:bold">نسبة الاستقطاع</label><br><br>
                 -->




                </v-col>


                <v-col>
                  
                  <label for="" v-for="address in items.Address"  :key="address.id">{{address.province.province_name}}</label><br><br>

                  <label v-if="items.offer.length==0">
                    {{items.price.price_value}} <span v-if="items.price.is_dollars==1">د.ع</span>  <span v-else-if="items.price.is_dollars==2">دولار</span> 

                  </label>


                  <label v-else>
                    {{items.offer[0].offer_price}} <span v-if="items.price.is_dollars==1">د.ع</span>  <span v-else-if="items.price.is_dollars==2">دولار</span> 
                  </label>



                  <!-- <label for="" v-else></label><br><br> -->

                  <!-- <label for="" v-if="item.item_price.free_cancellation==0">0%</label> -->

                  <!-- <label v-else> {{items.items_price.deduction}}%</label> -->


                  <br><br>
                  <label for="" :style="color"> {{getfreecancelation(items.price.free_cancellation)}}
                  </label>


                </v-col>


              </v-row>
              <v-row>
                <label for="">{{items.item_description}}</label>
                <br>
              </v-row>

              <!--Map Row -->


              <v-row class="mb-6">

                <v-container width="100px">
                  <google-map :lat="items.Address[0].lat" :long="items.Address[0].long" />
                </v-container>



              </v-row>
              <v-row>
                <v-btn color="#ff6600" style="margin-left:20px" width="200px" dark="" @click="booking">
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>
                  <span v-show="!loading">

                    <v-icon right="">fas fa-bookmark</v-icon>
                    حجز
                  </span>

                </v-btn>
              </v-row>
            </v-flex>
            <v-flex xs6 md5 background-color="green" class="mb-2">

              <v-layout row wrap justify-center style="background:#fff" v-if="items.day_to_open[0].every_day==0">


                <v-timeline v-for="item in items.day_to_open" :key="item.id">
                  <v-timeline-item class="text-right" if :color="item.status.id==23?'success':'red'">

                    <span style="font-weight:bold">{{getdays(item.work_day)}}</span>
                    <div v-if="item.status.id==23" style="width:220px;color:#808080;font-size:14px;float: left;padding-right: 50%;">
    
                      <v-chip small class="ma-2" v-for="item in item.time_to_open" :key="item.id">
                       <span style="color:red;padding-left:4px;"> من  </span> {{formatAMPM(item.from_time)}} <span style="color:red;padding-right:4px;padding-left:4px;" >  الى  </span> {{formatAMPM(item.to_time)}}
                      </v-chip>


                    </div>
                    <div v-else></div>
                    <div style="width:200px;color:#808080;font-size:14px"> <span></span></div>
                  </v-timeline-item>










                </v-timeline>





              </v-layout>
            </v-flex>
          </v-row>



        </v-container>

      </v-flex>

    </v-layout>









    <!--item booking dailog  -->


    <v-dialog v-model="booking_dialog" width="600">




      <BookingDialog @changeStatus="booking_dialog=false" @getUserReservationsCount="getUserReservationsCount" :ItemsReservationRequirements="items.ItemsReservationRequirements"
        @logout="logout" :pricepayment_when_receiving="items.price.payment_when_receiving" :price="items.price" :possib_reserving_period="items.possib_reserving_period" :itemid="ItemId"
        :day_to_open="day_to_open" v-if="booking_dialog" :ItemFeauter="itemFeauter"> </BookingDialog>


    </v-dialog>

    <!-- Rating dailog -->
    <v-dialog v-model="rating_dailog" width="400">
      <RatingDailog @hiddenDailog="rating_dailog=false" :item_id="items.id"></RatingDailog>
    </v-dialog>
    <!-- end rating dailog -->








  </v-container>
</template>

<script>
  import {
    //VueAgile
  } from 'vue-agile'
  import RatingDailog from './sub_components/ratingdailog'
  import GoogleMap from './sub_components/mapitemlocation'
  import BookingDialog from './sub_components/BookingDialog.vue'

  import {
    USER_ROLE
  } from "./constants.js";

  import {
    EventBus
  } from '../event-bus.js';
  export default {


    data: () => ({

      rating: 4,
      model: 0,
      day_to_open: [],
      image_url: '',
     
      rating_dailog: false,
      date: '',
      dayoffer: [],
      itemFeauter: [],
      ItemId: '',
      booking_dialog: false,
      loading: false,
      color: 'color:green',
      iMages: [],
      markers: [],
      items: {
        Address: {
          province: {
            province_name: ''
          }
        },
        price: {
          price_value: ''
        },
        offer: [

        ]

      },
      offers: [],


    }),
    mounted() {


      this.id = this.$route.params.id;
      this.getItemDetails();
    },

    created() {

       
            EventBus.$on('changeStatus', (from) => {
            from,
            this.dialog=false;
            this.getitems();

            });


            
    },
    methods: {


      formatAMPM(date) {
        if (typeof date === "string") {
          let [hours, minutes] = date.split(":");
          let ampm = "ص";

          if (Number(hours) > 12) {
            hours = Number(hours) - 12;
            ampm = "م";
          }

          return `${hours}:${minutes} ${ampm}`;

        } else if (date instanceof Date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          let ampm = hours >= 12 ? 'م' : "AM";

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let strTime = hours + ":" + minutes + " " + ampm;

          return strTime;
        }

        return date;
      },



      getUserReservationsCount() {
        this.$emit('getUserReservationsCount', true);
      },



      getItemDetails() {


        var url = "/v2/items/show/" + this.id;
        this.$http.get(url).then(response => {
          this.items = response.data.data;
          this.day_to_open = response.data.data.day_to_open;
          this.itemFeauter = response.data.data.ItemFeatures;
          this.iMages = this.items.images;
  

        })
      },

      getPath(img_path) {
        var path = this.Url + "/images/" + img_path;
  
        return path;

      },





      booking() {

        if (this.$cookies.get('user_info') !== 'no cookies' || this.$cookies.get('user_info') !== null)

        {
          if (this.$cookies.get('user_info').role == USER_ROLE) {
            this.booking_dialog = true;
          } else {
            EventBus.$emit('reg_dialog', 'fromItem');

          }
        }

      },







      getfreecancelation(free_cancellation) {
        if (free_cancellation == 0) {
          this.color = "color:red";
          return "كلا";
        } else {

          return "نعم"
        }
      },







      getdays(index) {



        var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];

        return days[index];
      }
    },
    filters: {
      durationFormat(value) {
        if (value) {
          const parts = value.split(":");
          return +parts[0] + "h " + +parts[1] + "m";
        } else {
          return "unknown"
        }
      },
    },


    components: {
      GoogleMap,
      RatingDailog,
      BookingDialog
    }



  }
</script>