<template>
  <v-container fluid="" style="background:white">

    <gmap-map id="googleMap" :center="center" :zoom="12" style="width:100%;  height: 400px;" @click.native="setPlace"
      :clickable="true" :draggable="true">
      <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center=m.position">
      </gmap-marker>
    </gmap-map>


  </v-container>
</template>

<script>
  export default {
    props: {
      'lat': String,
      'long': String
    },
    name: "GoogleMap",
    data() {
      return {

        center: {
          lat: '',
          lng: ''
        },
        markers: [],
        places: [],


        currentPlace: null
      };
    },

    mounted() {

      const marker = {
        lat: parseFloat(this.lat),
        lng: parseFloat(this.long)
      };

      this.markers.push({
        position: marker
      });
      this.places.push(this.position);
      this.center = marker;
      this.currentPlace = null;


      this.lat = this.getlocationposition.coords.latitude;
      this.lng = this.position.coords.longitude;

    },

    methods: {


    }
  };
</script>