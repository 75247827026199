<template>
  <div>
    <!-- <v-dialog  v-model="BookingDetails" width="600"> 

     <BookingDetails  :BookingInfo="BookingDetailsInfo">

      </BookingDetails>


    </v-dialog> -->



    <!--date from picker dailog-->
    <v-dialog v-model="date_dialog" width="500" height="auto">
      <v-date-picker locale="ar-iq" :min="date_today()"  year-icon="mdi-calendar-blank" scrollable
        prev-icon="mdi-skip-next" next-icon="mdi-skip-previous " :allowed-dates="allowedDates" color="#ff6600"
        v-model="date" class="mt-4" @change="select_date">

        <!-- <v-btn text color="primary" @click="menu = false">Cancel</v-btn> -->
        <v-btn color="primary" @click="date_dialog=false;get_times();time_av=true"
          style="font-family: 'Roboto' !important;">اختيار</v-btn>
      </v-date-picker>
    </v-dialog>
    <!--end date picker dailog-->



    <!--date to picker dailog-->
    <v-dialog v-model="date_to_dialog" width="500">
      <v-date-picker year-icon="mdi-calendar-blank" prev-icon="mdi-skip-next" next-icon="mdi-skip-previous "
        locale="ar-iq" landscape="true" color="#ff6600" v-model="date_to" class="mt-4" @change="select_to_date">
        <v-btn text color="primary" @click="date_dialog=false">حسنا</v-btn>
      </v-date-picker>
    </v-dialog>
    <!--end date picker dailog-->


    <!--time picker dailog-->
    <!-- <v-dialog v-model="time_dialog" width="500">
      <v-time-picker color="#ff6600" v-model="time" :allowed-hours="allowedHour" :allowed-minutes=" allowedMinutes"
        class="mt-4" @change="select_time">
      </v-time-picker>
    </v-dialog> -->
    <!--end time picker dailog-->




    <v-stepper v-model="e1">
      <v-stepper-header
        v-if="ItemFeauterAvailable ||ItemsReservationRequirements.length>0">
        <v-stepper-step v-if="day_to_open[0].every_day==0" step="1">تاريخ الحجز </v-stepper-step>


        <v-stepper-step v-if="ItemsReservationRequirements.length>0" :complete="e1 > 1" step="2"> مرفقات الحجز
        </v-stepper-step>

        <v-divider v-if=" ItemFeauterAvailable"></v-divider>

        <v-stepper-step v-if="ItemFeauterAvailable" :complete="e1 > 3" step="3"> المميزات</v-stepper-step>
        <!-- <v-divider v-if="pricepayment_when_receiving==0"></v-divider>
 -->


        <!-- <v-stepper-step v-if="pricepayment_when_receiving==0" :complete="e1 > 3" step="4">طريقه الدفع</v-stepper-step> -->



        <!-- <v-divider></v-divider> -->
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" v-if="day_to_open[0].every_day==0">
          <v-card class="mb-12" color="#fff" flat height="auto">



            <v-form ref="form" v-model="valid_day">
              <v-container>


                <v-layout row wrap>
                  <v-flex pa-3>

                    <v-text-field outlined dense readonly style="background-color:#fff" placeholder="اختر تاريخ الحجز"
                      append-icon="fas fa-calendar-day" required :rules="nameRules" v-model="date"
                      @click="date_dialog=true"></v-text-field>
                  </v-flex>
                </v-layout>


                <v-layout row wrap v-if="day_to_open[0].every_day==1">
                  <!-- <v-flex pa-3>
               
                    <v-text-field outlined dense readonly style="background-color:#fff" placeholder="اختر تاريخ الحجز"
                      append-icon="fas fa-calendar-day" required :rules="nameRules" v-model="date"
                      @click="date_dialog=true"></v-text-field>
                  </v-flex> -->
                </v-layout>

                <div v-if="time_av">
                  <p v-if="available_times.length==0">لاتوجد اوقات متاحه في هاذا اليوم</p>

                  <div v-else>

                    <span>اختر وقت الحجز</span>
                  </div>

                </div>
                <v-radio-group v-model="subs_time" required row :rules="TimeRules">


                  <div v-for="time  in available_times" :key="time">
                    <p>
                      <span v-if="time.reservation_type_id==2">

                        <!-- <span style="padding-right:20px"> عدد الحجوزات المتبقيه {{time.sub_time.length}}</span> -->
                        <v-radio
                          :label="formatAMPM(time.sub_time[0].from_sub_time)+'  عدد الحجوزات المتبقيه '+count_availble_per(time.sub_time)"
                          :value="ava_sub(time.sub_time)">

                        </v-radio>


                      </span>

                      <span v-else-if="time.reservation_type_id==1" v-for="sub_time  in time.sub_time" :key="sub_time">

                        <v-radio v-if="sub_time.reservation.length" disabled
                          :label="formatAMPM(sub_time.from_sub_time)+' محجوز'" :value="sub_time">

                        </v-radio>

                        <v-radio v-else :label="formatAMPM(sub_time.from_sub_time)" :value="sub_time">

                        </v-radio>


                      </span>

                      <span v-else-if="time.reservation_type_id==3" v-for="sub_time  in time.sub_time" :key="sub_time">

                        <v-radio :label="formatAMPM(time.sub_time[0].from_sub_time)" :value="time.sub_time[0]">

                        </v-radio>

                      </span>

                    </p>

                  </div>
                </v-radio-group>

              </v-container>
            </v-form>
          </v-card>




          <v-btn color="primary" @click="step1()"
            v-if="pricepayment_when_receiving==0 || ItemFeauterAvailable || ItemsReservationRequirements.length>0">
            التالي
          </v-btn>

          <v-btn v-else color="success" style=";width:130px" @click="booking_item()">
            <span v-show="!loading">حجز</span>
            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

          </v-btn>




          <v-btn text @click="close()">خروج</v-btn>
        </v-stepper-content>
        <!-- ItemFeauters steppers -->



        <v-stepper-content step="2">
          <v-card class="mb-12" color="fff">
            <p>مرفقات الحجز</p>
            <input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked" />
            <v-layout row wrap>





              <v-flex xs3 v-for="(item,index) in ItemsReservationRequirements" :key="item" pr-3>
                <p>{{item.requirement_name}}</p>
                <span style="color:#fff;display:none">


                  {{img_name}} </span>
                <v-card height="180px" width="250px" class="card_img">

                  <v-img :src="images[index]" height="100%" width="100%" class="card_img" v-if="images[index]!='ab'">
                    <v-btn icon="" style="padding-right: 24px;position:relative;left: 46px;"
                      @click="delete_img(img_id,index)">

                      <v-icon color="#fff">fas fa-window-close</v-icon>
                    </v-btn>

                  </v-img>



                  <v-btn icon v-else @click='pickFile(item.id,index)'>
                    <v-icon style="margin:0px" size="40">fas fa-plus-square</v-icon>
                  </v-btn>
                </v-card>

              </v-flex>




            </v-layout>

          </v-card>

          <v-divider style="margin-top:20px;margin-bottom:20px"></v-divider>






          <v-btn color="primary" @click="stepForItemReqItemFeatur()"
            v-if="ItemFeauter.length>0 ">
            التالي
          </v-btn>

          <!-- <v-btn color="primary" @click="e1=4" v-else-if="pricepayment_when_receiving==0 ">
            التالي
          </v-btn> -->


          <v-btn v-else color="success" style=";width:130px" @click="check_re()">
            <span v-show="!loading">حجز</span>
            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

          </v-btn>



          <v-btn style="position:relative;right:3%" color="primary" @click="e1 = 1" v-if="day_to_open[0].every_day==0">
            السابق
          </v-btn>


        </v-stepper-content>


        <v-stepper-content step="3">

          <span v-for="item in ItemFeauter" :key="item">
            <v-card style="margin-bottom:10px;background;red;width:98%;padding:10px">
              <v-row align="center">
                <v-checkbox v-model="post_data.item_features" hide-details :value="item" class="shrink mr-2 mt-0">
                </v-checkbox>
                <span style="padding-right:10px"> {{item.feature_name}}</span>
                <v-spacer></v-spacer>
                <v-chip style="margin-left:10px">IQD {{item.feature_price}} </v-chip>

              </v-row>
            </v-card>

          </span>
          <v-divider style="margin-top:20px;margin-bottom:20px"></v-divider>


          <!-- <v-btn color="primary" @click="stepForItemReq" v-if="pricepayment_when_receiving==0 ">
            التالي
          </v-btn> -->

          <v-btn color="success" style=";width:130px" @click="check_re()">
            <span v-show="!loading">حجز</span>
            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

          </v-btn>



          <v-btn style="position:relative;right:3%" color="primary" @click="e1 = 1" v-if="day_to_open[0].every_day==0">
            السابق
          </v-btn>




          <!-- <v-btn v-if="!stepForItemFuter" color="success" style=";width:130px" @click="booking_item()">
            <span v-show="!loading">حجز</span>
            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

          </v-btn>

          <v-btn v-else color="primary" @click="stepForItemFuter">
            التالي
          </v-btn>



          <v-btn style="position:relative;right:3%" color="primary" @click="e1 = 1">
            السابق
          </v-btn> -->

        </v-stepper-content>


        <!-- <v-stepper-content step="4" v-if="pricepayment_when_receiving==0">
          <v-card class="mb-12" color="fff">
            <v-form ref="form_fa" v-model="valid_pay">

              <div v-show="bill" v-if="price.is_tasdid_payment==1 && price.is_tasdid_payment==1">

                <v-radio-group v-model="post_data.Paymentـmethod">
                  <v-radio label="الدفع عن طريق زين كاش" :value="1"></v-radio>

                  <v-radio label="الدفع عن طريق فاتوره تسديد" :value="2"></v-radio>
                </v-radio-group>

              </div>


              <div v-show="bill" v-if="price.is_tasdid_payment==1 && price.is_tasdid_payment==0">
                <v-divider></v-divider>
                <v-card-title style="font-size:font-size: 18px;">
                  ويه تسديد صار تكدر تدفع فاتورتك وانت بالبيت من خلال رقم الفاتورة الي يوصلك برسالة ..
                  والتسديد من
                  خلال ادخال رقم بطاقة الدفع الكي كارد أوالماستر كارد
                </v-card-title>
                <v-divider></v-divider>


                <v-layout row wrap>
                  <v-flex pa-3>

                    <v-text-field outlined dense placeholder="ادخل رقم هاتفك لانشاء فاتوره" append-icon="fas fa-phone"
                      type="number" :rules="numRules" required v-model="post_data.phone"></v-text-field>
                  </v-flex>

                </v-layout>
                <v-col>

                </v-col>
              </div>

              <div v-if="price.is_zaincash_payment==1 &&price.is_tasdid_payment==0">





            </v-form>



          </v-card>

          <v-btn color="success" style=";width:130px" @click="booking_item()">
            <span v-show="!loading">حجز</span>
            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

          </v-btn>


          <v-btn v-if="ItemFeauter.length>0" style="position:relative;right:3%" color="primary" @click="e1 = 3">
            السابق
          </v-btn>

          <v-btn v-else-if="ItemsReservationRequirements.length>0" style="position:relative;right:3%" color="primary"
            @click="e1 = 2">
            السابق
          </v-btn>

          <v-btn v-else-if="day_to_open[0].every_day==0" style="position:relative;right:3%" color="primary"
            @click="e1 = 1">
            السابق
          </v-btn>

        </v-stepper-content> -->


        <v-stepper-content step="5">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <v-btn color="primary" style="position: relative;right: 3%;width: 103px;" @click="e1 = 1">


            Continue
          </v-btn>

          <v-btn text @click="close()">خروج</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>


<script>
  const axios = require('axios');
  //import VueFriendlyIframe from 'vue-friendly-iframe';
  // import moment from 'moment';
  import moment from 'moment/src/moment'
  //import BookingDetails from './BookingDetails.vue'
  import {
    EventBus
  } from '../event-bus.js';
  export default {

    props: {
      'itemid': Number,
      'day_to_open': Array,
      'ItemFeauter': Array,
      'price': Object,
      'ItemsReservationRequirements': Array,
    //  'pricepayment_when_receiving': Number,
      'possib_reserving_period': Number

    },



    data: () => ({
      BookingDetailsInfo: {},

      model: 0,
      time_av: false,
      e1: 1,
      BookingDetails: true,
      rating: 4,
      fawater_res_Dailog: false,
      rating_dailog: false,
      reservations_number: null,
      valid_day: true,
      valid_pay: true,
      date: '',
      date_to: '',
      sub_time_id: '',
      img_click: '',
      ItemFeauterAvailable: false,
      itemid: '',
      errors: [],
      images: ['ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab'],
      img_name: '',
      miniDate: new Date().toISOString().substr(0, 10),
      time: '',
      dayselectid: 0,
      dayoffer: [],
      date_dialog: false,
      date_to_dialog: false,
      time_dialog: false,
      booking_dialog: false,
      subs_time: {

      },
      address: '',
      ItemFeauterSelected: [],
      aa: [],
      allowedHour: [],
      fatwra_dialog: false,
      phoneReq: false,
      loading: false,
      bill: true,
      reserv_count: '',
      available_times: [],
      req_id: '',
      ReservationRequirements: [],
      color: 'color:green',



      numRules: [

        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
      ],
      nameRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],

      TimeRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],


      id: '',

      reserv_date: [],
      post_data: {

        item_id: "",
        Paymentـmethod:"",
        reservation_start_date: "",
        reservation_date: "",
        item_features: [],
        reservation_end_date: "",
        reservation_from_time: "",
        reservation_to_time: "",
        reservation_number: "1",
        deliverable: false,
        phone: "",
        ReservationRequirements: [],
        sub_time_id: "",
        zainCashUrl: '',
        



      },


      ave_data: {
        item_id: '',
        date: ''
      }

    }),
    mounted() {

      if (this.day_to_open[0].every_day == 1) {
        if (this.ItemsReservationRequirements.length > 0) {
          this.e1 = 2;
        } else {
          this.e1 = 4;
        }


      }

      if ( this.ItemFeauter.length == 0 && this.day_to_open[0].every_day == 1 &&
        this.ItemsReservationRequirements.length == 0) {
        this.booking_dialog = false;


        this.post_data.reservation_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.post_data.reservation_start_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.post_data.reservation_end_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.post_data.ReservationRequirements = this.ReservationRequirements;
        this.post_data.reservation_from_time = new Date().toLocaleTimeString('en-US', {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        });
        this.post_data.reservation_to_time = new Date().toLocaleTimeString('en-US', {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        });
        this.post_data.item_id = this.$route.params.id;

      }

      this.loading = true;




      this.$http({
          method: 'post',
          url: "/reservation/user/set",
          data: this.post_data,
          headers: {

          }
        })
        .then(response => {
          this.booking_dialog = false;
          this.$emit("changeStatus", false);

          this.$swal('', "    تم  الحجز بنجاح", 'success')


          this.$emit('getUserReservationsCount', true);







          this.$refs.form.reset()
          //    this.e1 = 1;



          if ("payId" in response.data) {
            this.fatwra_num = response.data.data.payId;
            this.$refs.form_fa.reset()
            this.fawater_res_Dailog = true;
          }
          // this.rating_dailog = true;



          //bookink item succesful

        })

        .catch(error => {
          error;
          this.booking_dialog = false;
          //      this.$swal('', "    تم  الحجز بنجاح", 'success')
          // this.$swal('', " فشل الحجز", 'error')
        }).finally(d => {
          d;
          this.booking_dialog = false;
          this.loading = false;
        });




      this.ItemFeauterAvailable = this.ItemFeauter.length > 0 ? true : false;


    },

    created() {
      EventBus.$emit('getUserReservationsCount', true);



    },
    methods: {

      check_re() {
        if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {

          this.e1 = 2;
          alert('يجب رفع جميع الصور المرفقه');
        } else {

          this.booking_item();
        }
      },
      booking_item() {



        //this.post_data.item_feathures.push(this.post_data.item_feathures);


        // if (!this.$refs.form_fa.validate() && this.pricepayment_when_receiving == 0) {

        //   //this.bil

        // }
        //  else {


        if (this.day_to_open[0].every_day == 0 && this.$refs.form.validate()) {
          this.post_data.sub_time_id = this.subs_time.id;
          this.post_data.reservation_from_time = this.subs_time.from_sub_time;
          this.post_data.reservation_to_time = this.subs_time.to_sub_time;
          this.post_data.item_id = this.$route.params.id;
          this.post_data.ReservationRequirements = this.ReservationRequirements;
          this.post_data.reservation_date = this.date;
          this.post_data.reservation_start_date = this.date;

          if (this.possib_reserving_period == 1) {

            this.post_data.reservation_end_date = this.date_to;
          } else {
            this.post_data.reservation_end_date = this.date;

          }


        } else if (this.day_to_open[0].every_day == 1) {


          this.post_data.reservation_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
          this.post_data.reservation_start_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
          this.post_data.reservation_end_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
          this.post_data.ReservationRequirements = this.ReservationRequirements;
          this.post_data.reservation_from_time = new Date().toLocaleTimeString('en-US', {
            hour12: false,
            hour: "numeric",
            minute: "numeric"
          });
          this.post_data.reservation_to_time = new Date().toLocaleTimeString('en-US', {
            hour12: false,
            hour: "numeric",
            minute: "numeric"
          });
          this.post_data.item_id = this.$route.params.id;

        }

        this.loading = true;




        this.$http({
            method: 'post',
            url: "/reservation/user/set",
            data: this.post_data,
            headers: {

            }
          })
          .then(response => {
            this.booking_dialog = false;
            this.$emit("changeStatus", false);

            this.$swal('', "    تم  الحجز بنجاح", 'success')


            this.$emit('getUserReservationsCount', true);







            this.$refs.form.reset()
            //    this.e1 = 1;



            if ("payId" in response.data) {
              this.fatwra_num = response.data.data.payId;
              this.$refs.form_fa.reset()
              this.fawater_res_Dailog = true;
            }
            // this.rating_dailog = true;



            //bookink item succesful

          })

          .catch(error => {
            error;
            this.booking_dialog = false;
            //      this.$swal('', "    تم  الحجز بنجاح", 'success')
            // this.$swal('', " فشل الحجز", 'error')
          }).finally(d => {
            d;
            this.booking_dialog = false;
            this.loading = false;
          });


        //  }


        if (this.info.item_price.book_without_credit_card == 0 && this.post_data.phone.length < 11) {
          //this.$swal('', "    تم  الحجز بنجاح", 'success')
          this.fatwra_dialog = true;
          return;
        }



      },

      date_today() {
        var x = new Date();
        return moment(String(x)).format('YYYY-MM-DD');

      },
      ava_sub(subs_time) {
        for (var i = 0; i < subs_time.length; i++) {
          if (subs_time[i].reservation.length == 0) {

            return subs_time[i];
          }
        }
      },


      count_availble_per(subs_time) {
        subs_time
        // var x;

        var peroid_time = 0;

        for (var i = 0; i < subs_time.length; i++) {

          if (subs_time[i].reservation.length == 0) {
            peroid_time = peroid_time + 1;


          }

          //x = peroid_time;
        }
        return peroid_time;

      },
      get_times() {

        this.ave_data.item_id = parseInt(this.$route.params.id);
        this.ave_data.date = this.date;


        this.$http({
            method: 'post',
            url: "/v2/reservation/GetAvilableReservationPeriods",
            data: this.ave_data

          })


          .then(response => {
            this.available_times = response.data.data;
            if (this.available_times.length == 0) {
               this.$swal('    خطاء', "   لاتوجد اوقات متاحه في هاذه الفتره", 'error')
           //    alert('لاتوجد اوقات متاحه في هاذه الفتره');
            }



          })

      },

      formatAMPM(date) {
        if (typeof date === "string") {
          let [hours, minutes] = date.split(":");
          let ampm = "ص";

          if (Number(hours) > 12) {
            hours = Number(hours) - 12;
            ampm = "م";
          }

          return `${hours}:${minutes} ${ampm}`;

        } else if (date instanceof Date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          let ampm = hours >= 12 ? 'م' : "AM";

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let strTime = hours + ":" + minutes + " " + ampm;

          return strTime;
        }

        return date;
      },
      close() {
         this.$emit("changeStatus", false);
        this.booking_dialog = false;
        this.date_dialog = false;
        this.$refs.form_fa.reset()
        this.e1 = 1;
       



      },

      step1() {



        this.errors = [];
        if (!this.date) {
          this.$refs.form.validate();

        } else if (this.subs_time.id === undefined) {
          this.$swal('  خطاء', "يرجئ اختيار وقت الحجز", 'error')

        } else {
          if (this.ItemsReservationRequirements.length > 0) {
            this.e1 = 2;
          } else {
            this.e1 = this.ItemFeauterAvailable ? 3 : 4;
          }

        }


      },

      stepForItemReq() {


        if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {

          this.e1 = 2;
        } 
        
        // else {
        //   this.e1 = this.pricepayment_when_receiving == 0 ? 4 : 2;
        // }






      },

      stepForItemReqItemFeatur() {


        if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {

          this.e1 = 2;
          alert('يجب رفع جميع الصور المرفقه');
        } else {
          if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {

            this.e1 = 2;
          } else {

            if (this.ItemFeauter.length > 0) {
              this.e1 = 3;

            } 
            
            // else if (this.pricepayment_when_receiving == 0) {
            //   this.e1 = 4;
            // }
            // this.e1 = this.pricepayment_when_receiving == 0 ? 4 : 2;
          }

        }






      },

      stepForItemFuter() {

        if (this.ItemsReservationRequirements.length > 0) {
          this.e1 = 2;
        }
        
        // else if (this.pricepayment_when_receiving == 0) {
        //   this.e1 = 4;
        // } 
        
        else {
          return false
        }



      }

      ,


      delete_img(img_id, index) {





        const Swal = require('sweetalert2');



        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'

        }).then((result) => {
          if (result.value) {
            this.ReservationRequirements = [];
            this.ReservationRequirements.length = 0;
            this.img_cliced = index;


            this.images[index] = 'ab';
            this.img_name = 'ghjk'


          }
        })




      },


      cancelImg() {
        this.images[0] = 'ab';
        this.img_name = 'ghjk'

      },
      pickFile(id, index) {
        this.req_id = id;
        this.img_click = index;
        this.$refs.image.click();
      },

      onFilePicked(e) {


        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]


            // this.images = [];
            var req = {
              item_reservation_requirements_id: this.req_id,
              images: [fr.result]

            }

            this.ReservationRequirements.push(req);
            this.images[this.img_click] = fr.result;

            // this.imgname = files[0].name;
            var a = this.img_click + 1;
            this.img_name = "   صورة رقم " + a



          })

        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      // selectAvatar(avatar) {
      //     this.form.avatar = avatar
      // }
    },


    validate_fawater() {
      if (this.$refs.form_fa.validate()) {
        this.fatwra_dialog = false;
      }
    },





    getDate_andTime(id) {

      var url = this.urL + "/getDaysTime/" + id;
      axios.get(url).then(response => {
        this.day_to_open = response.data.Data;

        for (var day = 0; day < 7; day++) {

          var offer = '';
          for (var i = 0; i < this.dayoffer.length; i++) {

            if (this.dayoffer[i] == day) {

              offer = 1;

            }



          }

          this.sechdual_info.push({
            day_name: this.getdays(day + 1),
            time_duration: ['غير متوفر'],
            status: false,
            offer: offer



          });
          for (i = 0; i < this.day_to_open.length; i++) {
            if (day >= this.day_to_open[i].from_day && day <= this.day_to_open[i].to_day) {

              var t = [];

              for (var time = 0; time < this.day_to_open[i].time_to_open.length; time++) {


                var from_time = this.day_to_open[i].time_to_open[time].from_time.split(":");
                var totime = this.day_to_open[i].time_to_open[time].to_time.split(":");



                t.push(from_time[0] + ":" + from_time[1] + " - " +
                  totime[0] + ":" + totime[1])

              }

              this.sechdual_info[day].time_duration = t
              this.sechdual_info[day].status = true



              this.timeline_status[day] = true;

            }
          }



        }



      })

    },
    getReserDate(from, to, time) {


      var url = '/reservation/SearchAvilableReservation?filter[item_id]=' + this.$route.params.id +
        '&filter[BetweenTime]=' + time + '&&filter[BetweenDate]=' + from +
        '_' + to + '&page=' + 1;


      this.$http({
          method: 'get',
          url: url,
          data: this.post_data,
          headers: {

          }
        })

        .then(response => {

          this.reserv_date = response.data.data;
          this.reserv_count = response.data.meta.count;
          for (var i = 0; i < this.reserv_date.length; i++) {
            // var hours = this.reserv_date[i].reservation_from_time.split(":");
            //var hour = parseInt(hours[0]);
            time = this.time.split(":");


            time = parseInt(time);



            const index = this.allowedHour.indexOf(time);

            if (index > -1) {
              this.allowedHour.splice(index, 1);
            }






            if (this.reservations_number !== null) {

              if (this.reserv_count > this.reservations_number) {
                this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم العدد ممتلئ", 'error')
                this.time = '';


              }


            } else if (!this.allowedHour.includes(time)) {
              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا الوقت", 'error')
              this.time = '';

            } else if (this.allowedHour.length == 0) {


              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم", 'error')
              this.time = '';

            } else if (this.allowedHour == null) {
              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم", 'error')
              this.time = '';

            }


          }

        });

    },
    allowedDates(val) {

      var d = new Date(val);

      for (var i = 0; i <= 6; i++) {
        if (d.getDay() == this.day_to_open[i].work_day) {

          if (this.day_to_open[i].status.id == 23) {
            return true;
          } else {
            return false;
          }
        }
      }

    },




    allowedHours(v) {
      v
    },

    allowedMinutes(v) {
      v
      return true;

    },

    select_to_date() {
      this.date_to_dialog = false;
      //http://109.224.27.9:81/api/v2/reservation/GetAvilableReservationPeriods
      // if (this.possib_reserving_period == 1) {

      //   this.post_data.reservation_end_date = this.date_to;
      // } else {
      //   this.post_data.reservation_end_date = this.date;

      // }

    },

    select_date() {
      // alert('xx');
      // alert(this.date);
      //this.date_dialog = false;
      this.post_data.reservation_start_date = this.date;

      this.post_data.reservation_date = this.date;
      if (this.possib_reserving_period == 1) {

        this.post_data.reservation_end_date = this.date_to;
      } else {
        this.post_data.reservation_end_date = this.date;

      }


      var d = new Date(this.date);

      this.allowedHour = [];
      for (var i = 0; i < 6; i++) {


        if (d.getDay() == this.day_to_open[i].work_day) {
          if (this.day_to_open[i].status.id == 23) {
            for (var j = 0; j < this.day_to_open[i].time_to_open.length; j++) {
              var hours1 = this.day_to_open[i].time_to_open[j].from_time.split(":");
              hours1 = parseInt(hours1[0]);
              var hours2 = this.day_to_open[i].time_to_open[j].to_time.split(":");
              hours2 = parseInt(hours2[0]);

              for (var k = hours1; k < hours2; k++) {
                this.allowedHour.push(k);
              }

            }
          }
        }



      }

    },



    select_time() {


      this.time_dialog = false;

      var d = new Date(this.date);
      for (var i = 0; i < 6; i++) {


        if (d.getDay() == this.day_to_open[i].work_day) {
          if (this.day_to_open[i].status.id == 23) {
            for (var j = 0; j < this.day_to_open[i].time_to_open.length; j++) {
              //  if(this.time>)

              var time = this.time.split(":");


              time = parseInt(time);

              var hours1 = this.day_to_open[i].time_to_open[j].from_time.split(":");
              hours1 = parseInt(hours1[0]);

              var hours2 = this.day_to_open[i].time_to_open[j].to_time.split(":");
              hours2 = parseInt(hours2[0]);

              if (time >= hours1 && time <= hours2) {
                if (this.day_to_open[i].time_to_open[j].reservation_type !== null)


                {
                  if (this.day_to_open[i].time_to_open[j].reservation_type.id == 2) {
                    this.reservations_number = this.day_to_open[i].time_to_open[j].reservations_number;
                    // alert(this.day_to_open[i].time_to_open[j].reservations_number);
                  } else {
                    this.reservations_number == null;

                  }
                } else {
                  this.reservations_number == null;

                }


              }
            }

          }
        }
      }

      this.post_data.reservation_from_time = this.time
      this.post_data.reservation_to_time = this.time

      this.getReserDate(this.date, this.date, this.time);



    },



    getPath(img_path) {

      return this.urL + "/" + img_path;

    },


















    getdays(index) {



      var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];

      return days[index];

    },

    components: {
      //VueFriendlyIframe
      // BookingDetails,
    }



  }
</script>